* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  user-select: none;
}

#root {
  overflow: auto;
  display: flex;
  justify-content: center;
}

.list {
  position: relative;
  width: 62ch !important;
  height: 100%;
}

.card {
  position: absolute;
  will-change: transform, height, opacity;
  width: 100%;
  z-index: 1 !important;
}

.cell {
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
}

.details {
  position: relative;
  margin-top: 3px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 10px 25px -10px rgba(0, 0, 0, 0.2);
}


.countertext {
  margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}